import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import getSymbolFromCurrency from 'currency-symbol-map'
import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'
import { ExportForm } from '@/classes/ExportForm'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsService from '@/services/stats.service'
import BrowserCache from '@/services/browser-cache.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import DeliveredWhatsapp from '@/components/Stats/Whatsapp/DeliveredWhatsapp.vue'
import OpeningWhatsapp from '@/components/Stats/Whatsapp/OpeningWhatsapp.vue'
import ClickingWhatsapp from '@/components/Stats/Whatsapp/ClickingWhatsapp.vue'
import UserInteractionWhatsapp from '@/components/Stats/Whatsapp/UserInteractionWhatsapp.vue'
import DeliveredDetailWhatsapp from '@/components/Stats/Whatsapp/DeliveredDetailWhatsapp.vue'
import { Colors } from '@/classes/colors/Colors'
import { WhatsappGraphicData } from '@/classes/stats/WhatsappGraphicData'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import MessagePreview from '@/components/Stats/Whatsapp/MessagePreview.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import WhatsappEditor from '@/models/whatsapp-editor/WhatsappEditor.js'
import HeadboardOption from '@/models/whatsapp-editor/HeadBoardOption'
import StatsMixin from '@/views/Pages/Stats/Mixins/StatsMixin'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import { Country } from '@/util/Country'
import HeadboardType from '@/models/whatsapp-editor/HeadboardType'
import { WhatsappError } from '@/models/whatsapp/WhatsappError'
import { TrackingEvents } from '@/classes/TrackingEvents'
import { CALL_TO_ACTION } from '@/models/whatsapp-editor/enums/CallToAction'
import { CALL_TO_ACTION_TYPE } from '@/models/whatsapp-editor/enums/CallToActionType'
import { AUTOMATIC_RESPONSE } from '@/models/whatsapp-editor/enums/AutomaticResponse'

export default {
  name: 'CampaignWhatsappStats',
  components: {
    apexchart: VueApexCharts,
    DeliveredWhatsapp,
    OpeningWhatsapp,
    ClickingWhatsapp,
    UserInteractionWhatsapp,
    DeliveredDetailWhatsapp,
    HeaderTopDashboard,
    StatCard,
    MessagePreview,
    RadialBarWithLegend,
    RadialBar,
    ConfirmCustomFieldsModal,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      sessionsContent: [],
      sessionsCostZeroContent: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalSendings: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      debouncedInput: '',
      timeout: null,
      exportForm: new ExportForm(),
      ready: true,
      campaign: {
        campaignId: this.$route.params.id,
      },
      whatsappEditor: new WhatsappEditor(),
      tags: null,
      senderName: null,
      senderMsisdn: null,
      templateWhatsapp: null,
      arrStatuses: Colors.statusesColors,
      channel: null,
      templateWhatsappHeaderTypeOptions: null,
      autoResponse: null,
      selectedHeaders: [],
      selectedSessionsHeaders: [],
      selectedSessionsCostZeroHeaders: [],
      selectedUndeliveredMessagesHeaders: [],
      sendingDetails: {
        initDate: '',
        endDate: moment().format('YYYY-MM-DD'),
        customFields: false,
        events: [],
        columns: [],
        sendingId: null,
        campaignType: null,
        sessions: null,
      },
      readyStats: false,
      readySessionStats: false,
      readySessionCostZeroStats: false,
      deliveredWhatsappData: {},
      openingWhatsappData: {},
      clickingWhatsappData: {},
      userInteractionWhatsappData: {},
      totals: {},
      cardWhatsappData: {
        show: false,
        cards: [],
      },
      deliveredRatioGraphic: null,
      deliveredSessionRatioGraphic: null,
      deliveredSessionCostZeroRatioGraphic: null,
      clicksRatioGraphicData: null,
      whatsappSendingClickTrackingSummary: null,
      whatsappSendingUndeliveredSummary: null,
      whatsappSendingClickTotal: 0,
      totalsSessions: new Map(),
      totalsSessionsCostZero: new Map(),
      callToActionResponses: [],
      automaticResponses: [],
    }
  },
  methods: {
    canUndeliveredRemarketing (item) {
      return item.description === WhatsappError.UNDELIVERED_UNDELIVERABLE
    },
    getResponse (clickTracking) {
      return clickTracking.automatic_responses[clickTracking.index_action - this.nCallToAction]
    },
    isDisableMarketing (clickTracking) {
      return this.getResponse(clickTracking)?.checkedDisableMarketing
    },
    setGraphicsData (response) {
      const whatsappGraphicData = new WhatsappGraphicData(response, this)
      this.cardWhatsappData.cards = whatsappGraphicData.getCardWhatsappGraphicData()
      this.cardWhatsappData.show = true
      this.deliveredRatioGraphic = whatsappGraphicData.getDeliveredRatioGraphicGraphicData()
      this.clicksRatioGraphicData = whatsappGraphicData.getClicksRatioGraphicData()
    },

    setSessionsGraphicsData (response) {
      const whatsappGraphicData = new WhatsappGraphicData(response, this)
      this.cardWhatsappData.show = true
      this.deliveredSessionRatioGraphic = whatsappGraphicData.getDeliveredSessionRatioGraphicGraphicData(this.totalsSessions)
      this.deliveredSessionCostZeroRatioGraphic = whatsappGraphicData.getDeliveredSessionRatioGraphicGraphicData(this.totalsSessionsCostZero)
    },

    sumItem (items, key) {
      let total = 0
      items.map((item) => {
        total = parseInt(total) + parseInt(item[key])
      })
      return total
    },

    sumItemCost (items, key) {
      let total = 0
      items.map((item) => {
        total = parseFloat(total) + parseFloat(item[key])
      })
      return total.toFixed(3)
    },

    headBoardTypeOptionName (type) {
      return this.$t(HeadboardOption.optionsText[this.templateWhatsappHeaderTypeOptions?.find((item) => item.id === type).name])
    },

    headBoardTypeName (type) {
      return this.$t(HeadboardType.optionsText[this.templateWhatsappHeaderType?.find((item) => item.id === type).name])
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.undelivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculatePending (data) {
      let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total)
      if (pending < 0) {
        pending = 0
      }
      return pending
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getWhatsappChannelDatatable(params)
      // StatsService.getWhatsappCampaignDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.totals = response.totals
            this.content = response.dataTable.data
            this.setGraphicsData(response)
            this.itemsPerPage = parseInt(response.dataTable.per_page)
            this.page = response.dataTable.current_page
            this.totalSendings = response.dataTable.total
            if (this.totalSendings > 0) {
              this.sendingDetails.initDate = moment(response.dataTable.data[0].campaign_sending_creation_date).format('YYYY-MM-DD')
              this.sendingDetails.sendingId = response.dataTable.data[0].sending_id
              this.sendingDetails.campaignType = response.dataTable.data[0].campaign_type_name
            }
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
          this.readyStats = true
        })
    },

    getSessionsDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.sessionsContent = []
      this.loading = true
      params = this.getParams()
      StatsService.getWhatsappCampaignSessionsDatatable(params)
          // StatsService.getWhatsappCampaignDatatable(params)
          .then(
              (response) => {
                this.readySessionStats = false
                this.readySessionCostZeroStats = false
                this.sessionsContent = response.dataTable
                this.sessionsCostZeroContent = response.dataTableCostZero
                this.sumTotal()
                this.sumTotalCostZero()
                this.setSessionsGraphicsData(response)
              },
              () => {},
          )
          .finally(() => {
            this.loading = false
            EventBus.$emit('showLoading', false)
            this.readySessionStats = this.sessionsContent.length > 0
            this.readySessionCostZeroStats = this.sessionsCostZeroContent.length > 0
          })
    },

    sumTotal () {
      this.totalsSessions.set('total', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_total)
      }, 0))
      this.totalsSessions.set('sent', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_sent)
      }, 0))
      this.totalsSessions.set('delivered', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_delivered)
      }, 0))
      this.totalsSessions.set('read', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_read)
      }, 0))
      this.totalsSessions.set('responded', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_responded)
      }, 0))
      this.totalsSessions.set('undelivered', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_undelivered)
      }, 0))
      this.totalsSessions.set('failed', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_failed)
      }, 0))
      this.totalsSessions.set('unsubscribed', this.sessionsContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_unsubscribed)
      }, 0))
    },
    sumTotalCostZero () {
      this.totalsSessionsCostZero.set('total', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_total)
      }, 0))
      this.totalsSessionsCostZero.set('sent', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_sent)
      }, 0))
      this.totalsSessionsCostZero.set('delivered', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_delivered)
      }, 0))
      this.totalsSessionsCostZero.set('read', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_read)
      }, 0))
      this.totalsSessionsCostZero.set('responded', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_responded)
      }, 0))
      this.totalsSessionsCostZero.set('undelivered', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_undelivered)
      }, 0))
      this.totalsSessionsCostZero.set('failed', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_failed)
      }, 0))
      this.totalsSessionsCostZero.set('unsubscribed', this.sessionsCostZeroContent.reduce((prev, curr) => {
        return prev + (curr.whatsapp_sendings_tracking_unsubscribed)
      }, 0))
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        campaignId: this.campaign.campaignId,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    getColumns () {
      const columns = []
      this.selectedHeaders.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportSessionsCsv (session) {
      this.exportSessionsAction(session, 'csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      const url = getEnv('backend_endpoint') + 'api/stats/whatsapp/campaign-export-' + urlSuffix + '?' + this.getUrlParams()
      window.location.href = url
    },

    exportSessionsAction (session, urlSuffix) {
      const url = getEnv('backend_endpoint') + 'api/stats/whatsapp/campaign-sessions-' + session + '-export-' + urlSuffix + '?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.setFromParamsAndColumns(this.getParams(), this.getColumns())).toString()
    },

    setFromParamsAndColumns (params, columns) {
      const data = {}
      data.campaignId = this.campaign.campaignId
      data.columns = JSON.stringify(columns)
      data.searchTerm = params.searchTerm
      data.sort = params.sortBy ? params.sortBy : ''
      const user = JSON.parse(localStorage.getItem('user'))
      data.token = user.token
      return data
    },

    selectedColumn (column) {
      let exist = false
      for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
        if (!exist) {
          exist = this.selectedHeaders[i].value === column
        }
      }
      return exist
    },

    getItemTotalsParsedObject (item) {
      return {
        7: parseInt(item.whatsapp_sendings_tracking_sent),
        1: parseInt(item.whatsapp_sendings_tracking_delivered),
        3: parseInt(item.whatsapp_sendings_click_tracking_total),
        9: parseInt(item.whatsapp_sendings_tracking_undelivered),
        4: parseInt(item.whatsapp_sendings_tracking_unsubscribed),
        91: parseInt(item.whatsapp_sendings_tracking_delivered) - parseInt(item.whatsapp_sendings_click_tracking_total),
        21: parseInt(item.whatsapp_sendings_tracking_responded),
        22: parseInt(item.whatsapp_sendings_tracking_read),
        23: parseInt(item.whatsapp_sendings_tracking_failed),
      }
    },

    showConfirmCustomFields (actionMode, item, customization = null) {
      if (customization) {
        this.sendingDetails.sessions = customization.sessions ?? 'open'
        this.sendingDetails.restrictedEvents = customization.restrictedEvents ?? []
        this.sendingDetails.errorDescription = customization.errorDescription ?? null
      }
      const totalEventValues = this.getItemTotalsParsedObject(item)
      EventBus.$emit('showConfirmCustomFields', actionMode, totalEventValues)
    },

    newCampaign () {
      EventBus.$emit('newCampaign', 'whatsapp')
    },
    statusColor (campaign) {
      return this.arrStatuses[campaign.guid]?.color
    },

    downloadFormAnswers () {
      EventBus.$emit('downloadFormAnswersEvent')
    },

    dateLocale (date) {
      return moment(date).format('DD/MM/YYYY hh:mm a')
    },
    downloadCsvPhonesByKey (action) {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('backend_endpoint') + 'api/stats/whatsapp/export-contact-phones-form-action-csv/' + this.channel.id + '/' + action + '?token=' + user.token
      window.location.href = url
    },

    getContactPhonesByPressedAction (action) {
      StatsService.getContactPhonesByPressedAction(this.sendingDetails.sendingId, action)
      .then(
        (response) => {
          localStorage.setItem('contact-phones-new-campaign', JSON.stringify(response))
          EventBus.$emit('newWhatsappCampaign')
        },
        () => { },
      )
    },
    exportUndeliveredMessages (item) {
      const user = JSON.parse(localStorage.getItem('user'))
      const share = this.$route.params.shareHash ? 'share/' : ''
      const params = {
        token: user?.token ?? null,
        sendingId: this.sendingDetails.sendingId,
        sendingEvents: TrackingEvents.UNDELIVERED_ID,
        errorDescription: item.description,
      }
      const urlParams = new URLSearchParams(params).toString()
      const url = `${getEnv('backend_endpoint')}api/${share}stats/whatsapp/sending-export-csv?${urlParams}`
      console.log('url', url)
      window.location.href = url
    },
  },
  computed: {
    hasUndeliveredMessages () {
      return this.whatsappSendingUndeliveredSummary?.length > 0 ?? false
    },
    nCallToAction () {
      return this.callToActionResponses.length ?? 0
    },
    nAutomaticResponses () {
      return this.automaticResponses.length ?? 0
    },
    Country () {
      return Country
    },
    headers () {
      return [
        {
          text: this.$t('Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Campaña'),
          value: 'campaign_name',
        },
        {
          text: this.$t('Categoría'),
          value: 'template_whatsapp_categories_name',
        },
        {
          text: this.$t('Fecha'),
          value: 'campaign_creation_date',
        },
        {
          text: this.$t('Contactos'),
          value: 'whatsapp_sendings_tracking_total',
        },
        {
          text: this.$t('Enviados'),
          value: 'whatsapp_sendings_tracking_sent',
        },
        {
          text: this.$t('Entregados'),
          value: 'whatsapp_sendings_tracking_delivered',
        },
        {
          text: this.$t('Leídos'),
          value: 'whatsapp_sendings_tracking_read',
        },
        {
          text: this.$t('Respondidos'),
          value: 'whatsapp_sendings_tracking_responded',
        },
        {
          text: this.$t('No entregados'),
          value: 'whatsapp_sendings_tracking_undelivered',
        },
        {
          text: this.$t('Fallidos'),
          value: 'whatsapp_sendings_tracking_failed',
        },
        {
          text: this.$t('Clics'),
          value: 'whatsapp_sendings_click_tracking_total',
        },
        {
          text: this.$t('Bajas'),
          value: 'whatsapp_sendings_tracking_unsubscribed',
        },
        {
          text: this.$t('Coste'),
          value: 'cost',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
          sortable: false,
          width: '14%',
        },
      ]
    },
    sessionHeaders () {
      return [
        {
          text: this.$t('País'),
          value: 'country',
        },
        {
          text: this.$t('Contactos'),
          value: 'whatsapp_sendings_tracking_total',
        },
        {
          text: this.$t('Enviados'),
          value: 'whatsapp_sendings_tracking_sent',
        },
        {
          text: this.$t('Entregados'),
          value: 'whatsapp_sendings_tracking_delivered',
        },
        {
          text: this.$t('Leídos'),
          value: 'whatsapp_sendings_tracking_read',
        },
        {
          text: this.$t('Respondidos'),
          value: 'whatsapp_sendings_tracking_responded',
        },
        {
          text: this.$t('No entregados'),
          value: 'whatsapp_sendings_tracking_undelivered',
        },
        {
          text: this.$t('Fallidos'),
          value: 'whatsapp_sendings_tracking_failed',
        },
        {
          text: this.$t('Bajas'),
          value: 'whatsapp_sendings_tracking_unsubscribed',
        },
        {
          text: this.$t('Coste Único'),
          value: 'cost_unique',
        },
        {
          text: this.$t('Coste Total'),
          value: 'cost_total',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
          sortable: false,
          width: '14%',
        },
      ]
    },
    sessionCostZeroHeaders () {
      return [
        {
          text: this.$t('País'),
          value: 'country',
        },
        {
          text: this.$t('Contactos'),
          value: 'whatsapp_sendings_tracking_total',
        },
        {
          text: this.$t('Enviados'),
          value: 'whatsapp_sendings_tracking_sent',
        },
        {
          text: this.$t('Entregados'),
          value: 'whatsapp_sendings_tracking_delivered',
        },
        {
          text: this.$t('Leídos'),
          value: 'whatsapp_sendings_tracking_read',
        },
        {
          text: this.$t('Respondidos'),
          value: 'whatsapp_sendings_tracking_responded',
        },
        {
          text: this.$t('No entregados'),
          value: 'whatsapp_sendings_tracking_undelivered',
        },
        {
          text: this.$t('Fallidos'),
          value: 'whatsapp_sendings_tracking_failed',
        },
        {
          text: this.$t('Bajas'),
          value: 'whatsapp_sendings_tracking_unsubscribed',
        },
        {
          text: this.$t('Coste Único'),
          value: 'cost_unique',
        },
        {
          text: this.$t('Coste Total'),
          value: 'cost_total',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
          sortable: false,
          width: '14%',
        },
      ]
    },
    messagesUndeliveredHeaders () {
      return [
        {
          text: this.$t('Motivo'),
          value: 'code',
        },
        {
          text: this.$t('Descripción'),
          value: 'title',
        },
        {
          text: this.$t('total'),
          value: 'total',
        },
        {
          text: this.$t('Porcentaje'),
          value: 'percent',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
          sortable: false,
          width: '14%',
        },
      ]
    },
    showHeaders () {
      return this.headers.filter(h => this.selectedHeaders.map(sh => sh.value).includes(h.value))
    },
    showSessionsHeaders () {
      return this.sessionHeaders.filter(h => this.selectedSessionsHeaders.map(sh => sh.value).includes(h.value))
    },
    showSessionCostZeroHeaders () {
      return this.sessionCostZeroHeaders.filter(h => this.selectedSessionsCostZeroHeaders.map(sh => sh.value).includes(h.value))
    },
    showUndeliveredMessagesHeaders () {
      return this.undeliveredMessagesHeaders.filter(h => this.selectedUndeliveredMessagesHeaders.map(sh => sh.value).includes(h.value))
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    templateWhatsappCategory () {
      return this.templateWhatsapp?.template_whatsapp_category?.name
    },
    hasHeadBoard () {
      return this.channel?.head_board !== 'null'
    },
    hasHeadBoardType () {
      return this.channel?.head_board_type !== 'null'
    },
    categoryName () {
      return this.templateWhatsapp?.template_whatsapp_category?.name
    },
    wabaId () {
      return this.templateWhatsapp?.whatsapp_waba_id
    },
    languageName () {
      return this.templateWhatsapp?.template_whatsapp_language?.name
    },
    hasAutoAnswer () {
      return this.channel?.has_auto_answer
    },
    hasClickTrackingSummary () {
      return this.whatsappSendingClickTrackingSummary?.length
    },
    sumTotalCost () {
      const total = this.content.reduce((prev, curr) => {
        return prev + (curr.cost)
      }, 0)
      return total
    },
  },
  watch: {
    params: {
      handler () {
        if (this.ready) {
          this.getDataFromApi()
          this.getSessionsDataFromApi()
        }
      },
      deep: true,
    },
  },

  created () {
    this.selectedHeaders = this.headers
    this.selectedSessionsHeaders = this.sessionHeaders
    this.selectedSessionsCostZeroHeaders = this.sessionCostZeroHeaders
  },

  mounted () {
    this.loading = true
    const campaignId = this.$route.params.id
    StatsService.getWhatsappCampaignData(campaignId)
      .then(
        (response) => {
          this.campaign = response.campaign
          this.tags = response.tags
          this.senderName = response.senderName
          this.senderMsisdn = response.channel.sender
          this.templateWhatsapp = response.templateWhatsapp
          this.whatsappEditor.load(response.templateWhatsapp)
          this.channel = response.channel
          this.autoResponse = JSON.parse(response.channel.auto_answer)
          this.callToActionResponses = JSON.parse(response.channel.call_to_action_responses)
          this.automaticResponses = JSON.parse(response.channel.automatic_responses)

          // this.automaticResponses = [{ visible: true, cols: 1, text: 'Texto botón 1', responseOption: 'automatic_response', type: 1 }, { visible: true, cols: 1, text: 'Texto botón 2', responseOption: 'automatic_response', type: 1 }, { visible: true, cols: 1, text: 'Texto botón 3', responseOption: 'automatic_response', type: 1 }, { visible: true, cols: 1, text: 'Texto botón 4', responseOption: 'automatic_response', type: 1 }, { visible: true, cols: 1, textDisableMarketing: 'Detener promociones', textDisableFooter: '\u00bfNo te interesa? Toca "Detener promociones"', checkedDisableMarketing: true, responseOption: 'automatic_response', type: 2 }, { visible: true, cols: 1, text: 'Texto botón 5', responseOption: 'automatic_response', type: 1 }, { visible: true, cols: 1, text: 'Texto botón 6', responseOption: 'automatic_response', type: 1 }]
          // this.callToActionResponses = [{ visible: true, cols: 1, text: 'Abrir enlace 1', url: 'https://example.com', example: null, customField: false, customFieldName: null, responseOption: 'call_to_action', type: 1 }, { visible: true, cols: 1, text: 'Abrir enlace 2', url: 'https://example.com', example: null, customField: false, customFieldName: null, responseOption: 'call_to_action', type: 1 }, { visible: true, cols: 1, text: 'Llamar tel\u00e9fono', phone: '645986142', phonePrefix: null, countryPrefix: 'ES', example: null, customField: false, customFieldName: null, responseOption: 'call_to_action', type: 2 }]

          this.automaticResponses = this.automaticResponses.map((automaticResponse) => {
            const foundResponse = AUTOMATIC_RESPONSE.find(option => option.type === automaticResponse.type)
            automaticResponse.buttonLabel = foundResponse.text
            return automaticResponse
          })

          this.callToActionResponses = this.callToActionResponses.map((callToActionResponse) => {
            const foundAction = CALL_TO_ACTION.find(option => option.type === callToActionResponse.type)
            callToActionResponse.buttonLabel = foundAction.text

            switch (callToActionResponse.type) {
              case CALL_TO_ACTION_TYPE.EXTERNAL_LINK:
                callToActionResponse.content = callToActionResponse.url
                break
              case CALL_TO_ACTION_TYPE.EXTERNAL_PHONE:
                callToActionResponse.content = callToActionResponse.phone
                break
              case CALL_TO_ACTION_TYPE.COPY_CODE:
                callToActionResponse.content = callToActionResponse.code
                break
              default:
                callToActionResponse.content = ''
                break
            }
            return callToActionResponse
          })

          this.templateWhatsappHeaderTypeOptions = response.templateWhatsappHeaderTypeOptions
          this.templateWhatsappHeaderType = response.templateWhatsappHeaderType
          this.whatsappSendingClickTrackingSummary = response.whatsappSendingClickTrackingSummary
          this.whatsappSendingUndeliveredSummary = response.whatsappSendingUndeliveredSummary
          if (this.whatsappSendingClickTrackingSummary.length > 0) {
            this.whatsappSendingClickTrackingSummary.map((clickTracking, index) => {
              this.whatsappSendingClickTrackingSummary[index].automatic_responses = JSON.parse(clickTracking.automatic_responses)
              this.whatsappSendingClickTotal += this.whatsappSendingClickTrackingSummary[index].total
            })
          }
        },
        () => {},
      )
      .finally(() => {
        this.loading = false
      })
  },
}
